<template>
  <transition name="fade">
    <div>
      <div class="d-flex flex-row w-100 justify-between head-title" >
        <span class="white--text font-30 app-medium-font mt-5" v-if="!isMember">  Members Register</span>
        <v-btn icon @click="goToHistory" v-if="isMember" class="history-btn"
          ><v-icon color="white">mdi-history</v-icon></v-btn
        >
      </div>
      <v-card class="membr-card" v-if="!isMember">
        <v-card-title> Register Member </v-card-title>
        <v-card-text>
          <v-form ref="form" v-if="!isMember" @submit.prevent="addMember">
            <div style="font-family: 'Poppins-Medium'; color: #1e1e1e">
              Select Agency
            </div>
            <v-select
              :items="members"
              v-model="selectedAgency"
              item-text="name"
              item-value="agency_id"
              label="Select Agency"
              solo
              flat
              outlined
              class="mt-2 app-regular-font"
              dense
              :error="errors.selectedAgency"
              :error-messages="
                errors.selectedAgency ? 'Agency is required' : ''
              "
            />
            <v-switch
              v-model="isToggled"
              label="Member"
              class="mt-2 app-regular-font"
              dense
              :error="errors.isToggled"
              :error-messages="
                errors.isToggled ? 'Member is required' : ''
              "
            />
            <v-btn 
            color="primary" 
            type="submit" 
            class="mt-5"
            :loading="loading"
            :disabled="loading"> Submit </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
       <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      selectedAgency: null,
      isToggled: false,
      regForm: true,
      errorMessage: null,
      snackbar: false,
      members: [],
      formattedMembersList: [],
      profiledata: null,
      isMember: null,
      loading: false,
      headers: [
        { text: "S.no", value: "sno" },
        { text: "Agency", value: "agency_id.name" },
        { text: "Requested Date", value: "request_date" },
        { text: "Responded Date", value: "response_date" },
        { text: "Status", value: "response_status" },
      ],
      errors: {
        selectedAgency: false,
        isToggled: false,
      },
    };
  },
  computed: {
    formattedMembers() {
      return this.formattedMembersList.map((member) => ({
        ...member,
        request_date: member.request_date
          ? this.formatDate(member.request_date)
          : "-",
        response_date: member.response_date
          ? this.formatDate(member.response_date)
          : "-",
      }));
    },
    ...mapState("auth", {
      profile: "profile",
    }),
   routername() {
      const routeName = this.$route.name; 
      return routeName; 
    },
  },
  methods: {
    ...mapActions({
      addMemberAction: "members/addMember",
      fetchAllAgency: "members/fetchAllAgency",
      fetchAgencyMembers: "members/fetchAgencyMembers",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    async addMember() {
      // Reset errors
      this.errors.selectedAgency = !this.selectedAgency;
      this.errors.isToggled = this.isToggled === false;

      // If no errors, submit the form
      if (!this.errors.selectedAgency && !this.errors.isToggled) {
        try {
          const params = {
            agency_id: this.selectedAgency,
            isMember: this.isToggled,
          };
          await this.addMemberAction(params);
          this.isMember = true;
          await this.onLoadMembers();
          this.loading = true;
          this.$router.push({ name: "members-history" });
        } catch (error) {
          this.loading = true
          this.showErrorMessage(
            error.message|| error || "An error occurred while adding the member."
          );
        } finally {
        this.loading = false; // Ensure loading stops even if an error occurs
      }
      }
    },
    async loadMembers() {
      try {
        this.loading = true;
        const response = await this.fetchAllAgency();
        this.members = response;
      } catch (error) {
        this.showErrorMessage(
          error.message || "An error occurred while loading members."
        );
      }
    },
    async onLoadMembers() {
      try {
        this.loading = true;
        const response = await this.fetchAgencyMembers();
        this.formattedMembersList = response;
        this.loading = false;
      } catch (error) {
        this.showErrorMessage(
          error.message || "An error occurred while loading members."
        );
      } finally {
        this.loading = false;
      }
    },
    goToHistory (){
        this.$router.push({ name: "members-history" });
    },

    pushroute() {
      const targetRoute = '/join-as-member'; 
      
      if (!this.isMember || !this.profile.isMember) {
          if (this.$route.path !== targetRoute) {
            this.$router.push(targetRoute).catch((err) => {
              if (err.name !== 'NavigationDuplicated') {
                console.error('Unexpected error during navigation:', err);
              }
            });
          }
      } else if (this.isMember || this.profile.isMember) {
          this.$router.push({ name: "members-history" });
      }
  
    }
  },

  watch: {
    profile(newProfile) {
      this.profiledata = newProfile;
      this.isMember = newProfile.isMember;
    this.pushroute();
    },
  },

  mounted() {
    if (this.profile) {
      this.isMember = this.profile.isMember;
      this.pushroute();
    }
    this.fetchAgencyMembers()
      .then((members) => {
        this.formattedMembersList = members;
      })
      .catch((error) => {
        this.showErrorMessage(
          error.message || "An error occurred while fetching members."
        );
      });
    this.loadMembers();
    this.pushroute();
    this.onLoadMembers();
  },
};
</script>
<style scoped>
.membr-card {
  margin-top: 5%;
}
.mem-title {
  margin-top: 1%;
  font-family: "Poppins-Medium" !important;
  margin-left: 17px;
}
/* .head-title{
  margin-top: 15%;
} */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.history-btn{
      margin-left: 100%;
    padding: 28px;
}
</style>